import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import { Element } from "react-scroll";
import Home from "./components/Home";
import { createTheme, Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import Solution from "./components/Solution";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Example from "./components/Notif";
const theme = createTheme({
  typography: {
    fontFamily: [ "Varela Round, sans-serif","Roboto"],
  },
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#ffab00',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 80,
  },
  home: {
    width: "80%",
    paddingTop: 56,
  },
  solution: {
    width: "80%",
    paddingTop: 56,
  },
  contact: {
    width: "60%",
    paddingTop: 56,
  },
}));
function App() {
  const classes = useStyles();
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header></Header>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Element name="home" className={classes.home}>
            <Home></Home>
          </Element>

          <Element name="solution" className={classes.solution}>
            <Solution></Solution>
          </Element>

          <Element name="contact" className={classes.contact}>
            <Contact></Contact>
          </Element>

        </Grid>
        <Footer></Footer>

      </ThemeProvider>
    </>
  );
}

export default App;
