import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
 footer:{
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#bdbdbd',
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    height: 30,
    width: '100%',
 },
 textFooter:{
     textAlign: 'center',
     display: 'block'
 }

}));
export default function Footer(){
    const classes = useStyles();

return <div className={classes.footer}>
    <Typography variant="caption" align="center" color="textPrimary" className={classes.textFooter}>IFC-Services. A platform to manage IFC models</Typography>
  </div>
}
  
