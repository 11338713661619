import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import TuneIcon from "@material-ui/icons/Tune";
import SettingsCellIcon from "@material-ui/icons/SettingsCell";
import { Link } from "react-scroll";

import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 200,
    width: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: theme.spacing(5),
  },
  card: {
    [theme.breakpoints.down("sm")]: {
      width: 300,
      height: 300,
    },
    width: 400,
    height: 300,
  },
  control: {
    padding: theme.spacing(2),
  },
  avatar: {
    marginTop: 12,
    marginLeft: 17,
    backgroundColor: "white",
  },
  description: {
    height: 70,
  },
}));

export default function Solution() {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={spacing}>
          <Grid key={1} item>
            <Card className={classes.card}>
              <CardActionArea>
                <Avatar variant="rounded" className={classes.avatar}>
                  <BusinessIcon color="primary" />
                </Avatar>

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Building Engineering
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    IFCServices platform provides operators with the analysis
                    (audit, data extraction) functionality of their IFC models.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <Link to="contact" spy={true} smooth={true} duration={500}>
                    Learn more
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid key={1} item>
            <Card className={classes.card}>
              <CardActionArea>
                <Avatar variant="rounded" className={classes.avatar}>
                  <HomeWorkIcon color="primary" />
                </Avatar>

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Real Estate Asset Management
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    The notion of asset management brings together the issues
                    facing project owners through their activity as operator and
                    "furniture service provider" to users, whether they are
                    tenants or (co) owners.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <Link to="contact" spy={true} smooth={true} duration={500}>
                    Learn more
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid key={1} item>
            <Card className={classes.card}>
              <CardActionArea>
                <Avatar variant="rounded" className={classes.avatar}>
                  <FilterCenterFocusIcon color="primary" />
                </Avatar>

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Building Operation
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Operation represents all of the actions directly enabling a
                    property to provide a specific service.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <Link to="contact" spy={true} smooth={true} duration={500}>
                    Learn more
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid key={1} item>
            <Card className={classes.card}>
              <CardActionArea>
                <Avatar variant="rounded" className={classes.avatar}>
                  <TuneIcon color="primary" />
                </Avatar>

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Building Maintenance
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Maintenance includes all actions to maintain or restore an
                    asset in a specified condition or able to provide a specific
                    service.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <Link to="contact" spy={true} smooth={true} duration={500}>
                    Learn more
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid key={1} item>
            <Card className={classes.card}>
              <CardActionArea>
                <Avatar variant="rounded" className={classes.avatar}>
                  <SettingsCellIcon color="primary" />
                </Avatar>

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Automation And Ease Of Use
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    The digital model can also offer advantages for the occupant of the building itself.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <Link to="contact" spy={true} smooth={true} duration={500}>
                    Learn more
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
