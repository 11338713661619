import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Button, Paper, Typography } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import MessageIcon from "@material-ui/icons/Message";
import PhoneIcon from "@material-ui/icons/Phone";
import BusinessIcon from "@material-ui/icons/Business";
import emailjs from "emailjs-com";
import Api from "./Api";
import queryString from "query-string";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",

    justifyContent: "center",
    textAlign: "center",
  },

  iconMessage: {
    paddingLeft: -30,
  },

  contactTitle: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
}));

export default function Contact() {
  const classes = useStyles();
  const contactFormInitialState = {
    name: {
      value: "",
      required: true,
      error: false,
      errorMessage: "",
    },
    mail: {
      value: "",
      required: true,
      error: false,
      errorMessage: "",
    },
    phone: {
      value: "",
      required: false,
      error: false,
      errorMessage: "",
    },
    company: {
      value: "",
      required: false,
      error: false,
      errorMessage: "",
    },
    message: {
      value: "",
      required: true,
      error: false,
      errorMessage: "",
    },
  };
  const [contactForm, setContactForm] = useState(contactFormInitialState);
  const handleInput = (event) => {

    const { name, value } = event.target;
    let error = false;
    let errorMessage = "";
    const required = contactForm[name].required;
    const whiteSpaceRegex = /^\s*$/;

    switch (name) {
      case "name": {
        if (required && whiteSpaceRegex.test(value)) {
          error = true;
          errorMessage = "The name is required";
        }

        break;
      }
      case "mail": {
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(value)) {
          error = true;
          errorMessage = "Invalid mail";
          break;
        }
        break;
      }
      case "company": {
        break;
      }
      case "phone": {
        const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
        if (value && !phoneRegex.test(value)) {
          error = true;
          errorMessage = "Invalid phone number";
          break;
        }
        break;
      }
      case "message": {
        if (required && whiteSpaceRegex.test(value)) {
          error = true;
          errorMessage = "A message is required to contact us";
        }
        break;
      }
    }

    setContactForm({
      ...contactForm,
      [name]: {
        ...contactForm[name],
        value: value,
        error: error,
        errorMessage: errorMessage,
      },
    });

    console.log(contactForm);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (
      !contactForm.name.value ||
      contactForm.name.error ||
      !contactForm.mail.value ||
      contactForm.mail.error ||
      contactForm.phone.error ||
      !contactForm.message.value ||
      contactForm.message.error
    )
    {
      console.log("please fix error(s)");
    }


    else {
      //sending mail
      const params = new URLSearchParams();
      params.append("msg", contactForm.message.value);
      params.append("lname", contactForm.name.value);
      params.append("email", contactForm.mail.value);
      params.append("cpy", contactForm.company.value);
      params.append("phone", contactForm.phone.value);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios
        .post(
          "http://www.ifc-services.com/scripts/sendcontactemails.php",
          params,
          config
        )
        .then((response) => {
          console.log(response);
        }).catch((error) => {
          console.log(error);

        } );
      NotificationManager.success('Ifc-Services', 'Thank you for contacting us');

      
    }
  };
  return (
    <form >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3" className={classes.contactTitle}>
          Contact us
        </Typography>
        <TextField
          onClick={handleInput}
          onChange={handleInput}
          className={classes.margin}
          name="name"
          value={contactForm.name.value}
          required={contactForm.name.required}
          error={contactForm.name.error}
          helperText={contactForm.name.errorMessage}
          label="Name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          className={classes.margin}
          value={contactForm.mail.value}
          error={contactForm.mail.error}
          required={contactForm.mail.required}
          helperText={contactForm.mail.errorMessage}
          onClick={handleInput}
          onChange={handleInput}
          name="mail"
          label="Mail"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={classes.margin}
          value={contactForm.company.value}
          error={contactForm.company.error}
          required={contactForm.company.required}
          helperText={contactForm.company.errorMessage}
          onClick={handleInput}
          onChange={handleInput}
          name="company"
          label="Company"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BusinessIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={classes.margin}
          value={contactForm.phone.value}
          error={contactForm.phone.error}
          helperText={contactForm.phone.errorMessage}
          onChange={handleInput}
          name="phone"
          label="Phone"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          className={classes.margin}
          value={contactForm.message.value}
          error={contactForm.message.error}
          helperText={contactForm.message.errorMessage}
          required={contactForm.message.required}
          onClick={handleInput}
          onChange={handleInput}
          name="message"
          label="Message"
          multiline
          minRows={4}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MessageIcon className={classes.iconMessage} />
              </InputAdornment>
            ),
          }}
        />

        <Button
          onClick={handleSend}
          variant="contained"
          color="secondary"
          className={classes.margin}
          type="submit"
        >
          Send
        </Button>
        <NotificationContainer/>

      </Grid>
    </form>
  );
}
