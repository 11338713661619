import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 200,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function MenuTitles({ titles }) {
  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          IFC-Services
        </ListSubheader>
      }
      className={classes.root}
    >
      {titles.map((title, index) => {
        return (
          <ListItem button>
              <ListItemIcon>{title.icon}</ListItemIcon>
              <Link to={title.href} spy={true} smooth={true} duration={500}>
              <ListItemText primary={title.title} />
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
}
