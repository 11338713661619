import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Drawer } from "@material-ui/core";
import MenuTitles from "./MenuTitles";
import buildingImage from "../images/buildings.jpeg";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import AppsIcon from '@material-ui/icons/Apps';
import { Link } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {

    position:"fixed",
    top: 0,
    
    // height:56
  },
  title: {
    flexGrow: 1,
    fontFamily:"Poiret One"
  },
  listTitles: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const titles = [
    {
      href: "home",
      title: "Home",
      icon: <AppsIcon />,
    },
    {
      href: "solution",
      title: "Solution",
      icon: <WbIncandescentIcon />,
    },

    {
      href: "contact",
      title: "Contact",
      icon: <ContactPhoneIcon />,
    },
  ];
  const [menuTitles, setMenuTitles] = useState(titles);
  const [menuOpen, setMenuOpen] = useState(false);
//   useEffect(() => {
//     setMenuTitles(titles);
//   }, menuTitles);

  const handleOpenMenu = (value) => {
    setMenuOpen(value);
  };
  return (
    <div className={classes.root}>
      <AppBar className={classes.toolbar} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => handleOpenMenu(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            IFC-Services
          </Typography>

          <div className={classes.listTitles}>
            {menuTitles.map((title, index) => {
              return (
                <Link to={title.href} spy={true} smooth={true} duration={500}>
                <Button  color="inherit">
                  {title.title}
                </Button>
                </Link>
                
              );
            })}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={menuOpen}
        onClose={() => handleOpenMenu(false)}
      >
        <MenuTitles titles={menuTitles} />
      </Drawer>
    </div>  
  );
}
