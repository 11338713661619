import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import homeImage from "../images/home.jpg";
import { Button } from "@material-ui/core";
import { Link } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    boxShadow: "none",
    justifyContent: "center",
  },
  details: {
    display: "flex",
    
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:"center",
    textAlign:"center"

  },
  cover: {
    minWidth: 300,
    minHeight: 150,
    width: "80%"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title:{
      marginBottom: theme.spacing(2)
  },
  knowMore:{
      marginTop:theme.spacing(2)
  }
}));

export default function Home() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root} >
        <CardContent className={classes.content}>
          <Typography  variant="h4" className={classes.title}>
            La Plateforme IFCServices est destinée aux entreprises utilisant des
            maquettes numériques dans le cadre de :
          </Typography>
          <Typography  variant="subtitle1" color="textSecondary">
            Ingénierie pour analyse des maquettes IFC
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Gestion de patrimoine immobilier (Gestion des Surfaces locatives)
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Exploitation et Maintenance, Facility Management
          </Typography>
          <Button variant="contained" color="secondary" className={classes.knowMore}>
              <Link to='solution' spy={true} smooth={true} duration={500}>
                     En savoir plus
              </Link>
          </Button>
          <img src={homeImage} className={classes.cover}></img>


        </CardContent>
    </Card>
  );
}
